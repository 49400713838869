<template>
	<el-dialog v-dialogDrag title="新增事件" :visible.sync="visible" width="600px" :before-close="close">
		<el-form ref="form" label-width="100px" :model="formParam" :rules="rules" v-loading="formLoading">
			<el-form-item label="事件类型" prop="incidentTypeId">
				<el-cascader v-model="formParam.incidentTypeId" :options="tree" @change="treeChange"
					:show-all-levels="false" :props="{label:'typeName',children: 'children',value:'id'}"
					placeholder="工作类"></el-cascader>
			</el-form-item>
			<el-form-item label="事件名称" prop="incidentName">
				<el-input maxLength="15" v-model="formParam.incidentName" placeholder="请输入事件名称"></el-input>
			</el-form-item>
			<el-form-item label="事件描述" prop="incidentDesc">
				<el-input type="textarea" v-model="formParam.incidentDesc" :rows="3" maxLength="100"
					placeholder="建议描述事件在多场景使用时奖扣分值的详情"></el-input>
			</el-form-item>
			<el-form-item label="A分" prop="aMin">
				<el-input class="smallInput" v-model.number="formParam.aMin" placeholder="分值范围" />
				&#12288;- &#12288;
				<el-input class="smallInput" v-model.number="formParam.aMax" placeholder="分值范围" />
			</el-form-item>
			<el-form-item label="B分" prop="bMin">
				<el-input class="smallInput" v-model.number="formParam.bMin" placeholder="分值范围" />
				&#12288;- &#12288;
				<el-input class="smallInput" v-model.number="formParam.bMax" placeholder="分值范围" />
			</el-form-item>
			<el-form-item label="产值" prop="outputMin">
				<el-input class="smallInput" v-model.number="formParam.outputMin" placeholder="分值范围" />
				&#12288;- &#12288;
				<el-input class="smallInput" v-model.number="formParam.outputMax" placeholder="分值范围" />
			</el-form-item>
			<el-form-item label="事件状态">
				<el-radio v-model="formParam.incidentStatus" :label="1">启用</el-radio>
				<el-radio v-model="formParam.incidentStatus" :label="0">停用</el-radio>
			</el-form-item>
			<div class="title">
				高级设置
			</div>
			<div class="check">
				<p>
					<el-checkbox v-model="formParam.isLottery" :true-label="1" :false-label="0">奖票事件</el-checkbox>
					<span>(勾选奖票事件，则该事件审批通过后将相应生成奖票)</span>
				</p>
				<p>
					<el-checkbox v-model="formParam.isPiecework" :true-label="1" :false-label="0">计件事件</el-checkbox>
					<span>(勾选计件事件，可量化事件数量奖扣)</span>
				</p>
			</div>
		</el-form>
		<span slot="footer" class="dialog-footer">
			<el-button size="small" @click="close">取 消</el-button>
			<el-button size="small" type="primary" @click="submit">确 定</el-button>
		</span>
	</el-dialog>
</template>

<script>
	export default {
		data() {
			return {
				formParam: {
					incidentName: "",
					incidentStatus: 1
				},
				showIncidentTypeId: [],
				rules: {
					incidentTypeId: [{
						required: true,
						message: '请选择事件类型',
						trigger: 'change'
					}],
					incidentName: [{
						required: true,
						message: '请输入事件名称',
						trigger: 'blur'
					}],
				},
				formLoading: false,
			}
		},
		props: {
			show: {
				type: Boolean,
				default: false,
			},
			tree: {
				type: Array,
				default: () => {
					return []
				},
			},
			id: {
				type: Number | String,
				default: null
			}
		},
		computed: {
			visible: {
				get() {
					if (this.id) {
						this.getDetail()
					}
					return this.show;
				},
				set(val) {
					this.$emit("update:show", val);
				},
			},
		},
		watch: {},
		created() {},
		mounted() {},
		methods: {
			treeChange(e) {
				if (e) {
					this.formParam.incidentTypeId = e[0]
				}
			},
			// 关闭
			close() {
				this.$refs.form.resetFields();
				this.$emit('update:show', false)
			},
			// 获取详情
			async getDetail() {
				this.formLoading = true;
				let res = await this.$get(`/platform-config/incident/${this.id}`);
				this.formLoading = false;
				if (res && res.code == 0) {
					this.formParam = res.data
				}
			},
			// 点击确认提交
			submit() {
				this.$refs.form.validate((valid) => {
					if (valid) {
						if (this.id) {
							this.update()
						} else {
							this.save()
						}
					}
				});
			},
			// 新增
			async save() {
				let res = await this.$post("/platform-config/incident", this.formParam);
				if (res && res.code == 0) {
					this.$message.success("新增成功！");
					this.$emit('update:show', false)
					this.$parent.getList()
				}
			},
			// 编辑
			async update() {
				let res = await this.$put('/platform-config/incident', this.formParam);
				if (res && res.code == 0) {
					this.$message.success("修改成功！");
					this.$emit('update:show', false)
					this.$parent.getList()
				}
			},
		},
	};
</script>

<style lang="scss" scoped>
	.smallInput {
		width: 200px;
	}

	.title {
		height: 40px;
		line-height: 40px;
		background-color: rgb(232, 243, 255);
		padding: 0 30px;
	}

	.check {
		padding-left: 100px;

		>p {
			margin-top: 15px;
			>span {
				font-size: 12px;
				color: #999;
			}
		}
	}
</style>
