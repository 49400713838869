<template>
	<div>
		<div class="warp">
			<div class="left">
				<div class="btnBox">
					<el-button size="small" type="primary" icon="el-icon-circle-plus"
						v-if="isAuth('platform-config:templateinfo:saveTempIncident')" @click="openSelectEvent">
						选择事件</el-button>
				</div>
				<div class="tree-container">
					<div class="all" @click="activeAllFun" :class="{active:activeAll}">全部</div>
					<el-tree v-loading="treeLoading" highlight-current class="tree" :indent="0" :highlight-current="!activeAll" :data="data"
						@node-click="clickNode" :props="{label:'typeName',children: 'children'}">
						<span class="custom-tree-node" slot-scope="{ node, data }">
							<span>{{ data.typeName }}</span>
							<span>
								<img @click.stop="delType(data)" src="@/assets/img/icon/icon_delete.png" alt="">
							</span>
						</span>
					</el-tree>
				</div>
			</div>
			<div class="right">
				<el-scrollbar style="height:100%">
					<!-- <el-page-header @back="goBack" content="事件配置"></el-page-header> -->
					<jf-layout>
						<template slot="head">
							<div class="flex_bet">
								<div>
									<el-button class="el-icon-arrow-left" size="small" @click="goBack">返回</el-button>
								</div>
								<el-form :inline="true">
									<el-form-item>
										<el-input size="small" v-model="pageParam.incidentName" placeholder="请搜索事件名称"
											clearable>
										</el-input>
									</el-form-item>
									<el-form-item>
										<el-button size="small" class="ml10" type="primary" icon="el-icon-search"
											@click="getList('reset')">查询</el-button>
									</el-form-item>
									<el-form-item>
										<!-- <el-button class="el-icon-arrow-left" size="small" @click="goBack">返回</el-button> -->
									</el-form-item>
								</el-form>
							</div>
							<div class="hint">
								<div>已选中<span class="c-warning">{{selectArr.length}}</span>条</div>
								<div>
									<el-button size="mini" type="danger" icon="el-icon-delete"
										:disabled="selectArr.length<=0"
										v-if="isAuth('platform-config:incident:deleteTemp')" @click="delRow()">解绑
									</el-button>
								</div>
							</div>
						</template>

						<el-table slot="body" border :data="list" v-loading="tableLoading"
							@selection-change="getSelect">
							<el-table-column align="center" type="selection" width="50" />
							<el-table-column align="center" prop="incidentName" label="事件名称" />
							<el-table-column align="center" prop="incidentDesc" label="事件说明" />
							<el-table-column align="center" prop="incidentTypeName" label="事件类型" />
							<el-table-column align="center" label="A分">
								<template slot-scope="{row}">
									{{row.aMin||0}}~{{row.aMax||0}}
								</template>
							</el-table-column>
							<el-table-column align="center" label="B分">
								<template slot-scope="{row}">
									{{row.bMin||0}}~{{row.bMax||0}}
								</template>
							</el-table-column>
							<el-table-column align="center" label="产值">
								<template slot-scope="{row}">
									{{row.outputMin||0}}~{{row.outputMax||0}}
								</template>
							</el-table-column>
							<el-table-column align="center" prop="incidentStatus" label="状态">
								<template slot-scope="{row}">
									<el-tag type="success" v-if="row.incidentStatus==1">启用</el-tag>
									<el-tag type="warning" v-if="row.incidentStatus==0">禁用</el-tag>
								</template>
							</el-table-column>
							<el-table-column align="center" label="操作" width="180">
								<template slot-scope="{row}">
									<el-button type="text" size="mini" icon="el-icon-edit"
										v-if="isAuth('platform-config:incident:update')" @click="openEvent(row)">编辑
									</el-button>
									<el-button class="c-danger-imp" type="text" size="mini"
										v-if="isAuth('platform-config:incident:deleteTemp')" icon="el-icon-delete"
										@click="delRow(row)">解绑</el-button>
								</template>
							</el-table-column>
						</el-table>
						<jf-page slot="foot" :total="total" :page.sync="pageParam.page" :limit.sync="pageParam.limit"
							@change="getList()" />
					</jf-layout>
				</el-scrollbar>
				<AddType :show.sync="addTypeShow" :tree="data" />
				<AddEvent :show.sync="addEventShow" :tree="data" :id="id" />
				<SelectEvent :show.sync="selectEventShow" :selectTree="data"></SelectEvent>
			</div>
		</div>
	</div>
</template>
<script>
	import AddType from "./addType";
	import AddEvent from "./addEvent";
	import SelectEvent from "./selectEvent";
	export default {
		components: {
			AddType,
			AddEvent,
			SelectEvent
		},
		data() {
			return {
				addTypeShow: false,
				addEventShow: false,
				selectEventShow: false,
				data: [],
				pageParam: {
					page: 1,
					limit: 10
				},
				total: 0,
				list: [],
				id: null,
				tableLoading: false,
				treeLoading:false,
				selectArr: [],
				activeAll: true, //是否点击全部
			};
		},
		created() {
			this.getTree();
		},
		methods: {
			// 获取事件类型
			async getTree() {
				let id = this.$route.query.id;
				let res = await this.$get('/platform-config/incidenttype/selectTemplateTree', {
					templateId: id
				});
				if (res && res.code == 0) {
					this.data = res.data;
					this.getList()
				}
			},
			//处理回显的事件类型
			dealIncidentTypeId(id, data) {
				let name
				data.map((v) => {
					if (name) return
					if (v.id == id) {
						name = v.typeName
						return
					} else if (v.children && v.children.length > 0) {
						name = this.dealIncidentTypeId(id, v.children)
					}
				})
				return name
			},
			// 获取列表
			async getList(reset) {
				this.tableLoading = true;
				if (reset) {
					this.pageParam.page = 1
				}
				this.pageParam.templateId = this.$route.query.id;
				let res = await this.$get('/platform-config/incident/page', this.pageParam);
				this.tableLoading = false;
				if (res && res.code == 0) {
					this.list = res.data.list;
					this.list.forEach((v) => {
						v.incidentTypeName = this.dealIncidentTypeId(v.incidentTypeId, this.data) || v
							.incidentTypeId;
					})
					this.total = res.data.total;
				}
			},
			// 打开新增  编辑弹窗
			openEvent(row) {
				this.addEventShow = true;
				this.id = row ? row.id : null
			},
			// 点击  选择事件
			openSelectEvent() {
				this.selectEventShow = true;
			},
			// 选择
			getSelect(e) {
				this.selectArr = e
			},
			// 点击选择全部
			activeAllFun() {
				this.activeAll = true;
				this.pageParam.incidentTypeId = null;
				this.getList()
			},
			// tree 点击
			clickNode(e) {
				this.pageParam.incidentTypeId = e.id;
				this.activeAll = false
				this.getList()
			},
			// 删除
			delRow(row) {
				let ids = []
				if (row) {
					ids = [row.id]
				} else {
					this.selectArr.map((v) => {
						ids.push(v.id)
					})
				}
				let param = {
					templateId: this.$route.query.id,
					incidentId: ids
				}
				this.$confirm('确定执行解绑操作?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					this.tableLoading = true;
					let res = await this.$del('/platform-config/incident/deleteTempIncident', param);
					this.tableLoading = false;
					if (res && res.code == 0) {
						this.$message.success("解绑成功！")
						this.getList()
					}
				}).catch(() => {});
			},
			// 删除类型
			delType(data) {
				this.$confirm('确认删除?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let param={
						incidentTypeId:[data.id],
						templateId:this.$route.query.id
					};
					this.treeLoading = true;
					this.$del('/platform-config/incidenttype/deleteTempIncidentType',param).then(res=>{
						if(res&&res.code==0){
							this.$message.success("删除成功！");
							this.getTree()
						}
						this.treeLoading = false;
					})
					
				}).catch(() => {});
			},
			// 返回
			goBack() {
				this.$router.go(-1)
			},
		},
	};
</script>

<style scoped lang="scss">
	.warp {
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		right: 0;
		display: flex;

		.left {
			width: 300px;
			border-right: 1px solid #eee;
			padding: 20px;

			.add {
				margin-top: 3px;
			}
		}

		.btnBox {
			text-align: center;
		}

		.right {
			flex: 1;
			padding: 20px;
		}
	}

	.tree-container {
		margin-top: 20px;
		overflow: hidden;

		>.all {
			padding: 0 23px;
			height: 40px;
			line-height: 40px;
			border-bottom: 1px solid #eee;
		}

		.all:hover {
			background-color: #F5F7FA;
		}

		.active {
			background-color: #F5F7FA;
		}
	}

	.tree {
		max-height: 90vh;
	}

	.tree /deep/ .el-tree-node {
		position: relative;
		padding-left: 0;
	}

	.tree /deep/ .el-tree-node__children {
		padding-left: 16px;
	}

	.tree /deep/ .el-tree-node :last-child:before {
		height: 12px;
	}

	.tree /deep/ .el-tree>.el-tree-node:before {
		border-left: none;
	}

	.tree-container /deep/ .el-tree>.el-tree-node:after {
		border-top: none;
	}

	.tree /deep/ .el-tree-node:before {
		content: "";
		left: -4px;
		position: absolute;
		right: auto;
		border-width: 1px;
		border-left: 1px dashed #999;
		bottom: 0px;
		height: 100%;
		top: 0px;
		width: 1px;
	}

	.tree /deep/ .el-tree-node:after {
		content: "";
		left: -4px;
		position: absolute;
		right: auto;
		border-width: 1px;
		border-top: 1px dashed #999;
		height: 20px;
		top: 12px;
		width: 10px;
	}

	.tree /deep/ {
		.el-tree-node__content {
			height: 40px !important;
			border-bottom: 1px solid #eee;
		}
	}

	.ml10 {
		margin-left: 10px;
	}

	.hint {
		display: flex;
		justify-content: space-between;
		background: #FFF4E3;
		border-radius: 5px;
		padding: 0 17px;
	}

	/deep/ .el-scrollbar__wrap {
		overflow-x: hidden;
	}

	.custom-tree-node {
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 14px;
		padding-right: 8px;

		img {
			margin-left: 5px;

			&:hover {
				opacity: 0.7;
			}
		}
	}
</style>
