<template>
	<el-dialog v-dialogDrag title="新增事件类型" :visible.sync="visible" width="600px" :before-close="close">
		<el-form :model="param" ref="form" label-width="100px">
			<el-form-item label="类型名称" prop="typeName">
				<el-input v-model="param.typeName" maxLength="15"></el-input>
			</el-form-item>
			<el-form-item label="上级类型" prop="pid">
				<el-cascader v-model="pid" :options="tree" @change="treeChange" :show-all-levels="false"
					:props="{checkStrictly:true,label:'typeName',children: 'children',value:'id'}" clearable></el-cascader>
			</el-form-item>
		</el-form>
		<span slot="footer" class="dialog-footer">
			<el-button size="small" @click="close">取 消</el-button>
			<el-button size="small" type="primary" @click="submit()">确 定</el-button>
		</span>
	</el-dialog>
</template>

<script>
	export default {
		data() {
			return {
				param: {
					typeName:null,
					pid:null
				},
				pid:null,
				showPid:null
			}
		},
		props: {
			show: {
				type: Boolean,
				default: false,
			},
			tree: {
				type: Array,
				default: () => {
					return []
				},
			},
		},
		computed: {
			visible: {
				get() {
					return this.show;
				},
				set(val) {
					this.$emit("update:show", val);
				},
			},
		},
		watch: {
			tree(n, o) {
				this.dealTree(n)
			}
		},
		created() {},
		mounted() {},
		methods: {
			// 处理tree
			dealTree(tree) {
				tree.forEach((v) => {
					if (v.children.length > 0) {
						this.dealTree(v.children)
					} else {
						v.children = null
					}
				})
			},
			treeChange(e) {
				this.param.pid = e[e.length-1];
			},
			// 关闭
			close() {
				this.$refs.form.resetFields();
				this.$emit('update:show',false)
			},
			// 提交
			async submit() {
				if (!this.param.typeName) {
					this.$message.warning("请输入类型名称！");
					return
				}
				this.param.pid = this.param.pid ? this.param.pid : "0";
				let res = await this.$post('/platform-config/incidenttype', this.param);
				if (res && res.code == 0) {
					this.$message.success("新增成功！");
					this.$emit('update:show', false);
					this.$parent.getTree()
				}
			}
		},
	};
</script>

<style scoped></style>
